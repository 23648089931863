<template>
  <div style="background: linear-gradient(0deg, #F4F5F7 70%, #AC76FA 100%);height: 100%;">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 0.6rem;line-height: normal;">投票</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 20px;">
      <van-row v-for="(item, index) in polllist" :key="index" style="margin-bottom: 20px;" @click="showPopup(item)">
        <van-col span="24" style="padding: 20px;border-radius: 10px;background: #fff;position: relative;">
          <van-row>
            <van-col span="18" style="font-size: 0.45rem;font-weight: 550;">{{ item.name }}</van-col>
            <div span="6" style="font-size: 0.45rem;background-color: #AC76FA;border-radius: 20px 0px 0px 20px;height: 0.7rem;font-weight: 550;text-align: center;padding-top: 1%;width: 30%;position: absolute;right: 0;top: 8%;">No.{{ item.period }}</div>
          </van-row>
          <van-row style="margin-top: 10px;">
            <van-col span="24" style="font-size: 12px;color: #9DA4B2;">{{ item.text }}</van-col>
          </van-row>
          <div style="display: flex;position: relative;padding-bottom: 25%;">
          <van-row style="margin-top: 10px;position: absolute;background: linear-gradient(90deg, #6CF5C3 70%, #38EBAC 100%);height: 1.3rem;width: 49%;border-radius: 10px;">
            <img src="../../assets/images/pollicon2.png" alt="" style="position: absolute;" />
            <van-col span="24" style="font-size: 0.5rem;color: #AC76FA;margin-left: 35%;padding-top: 8%;">{{ item.a_text }}</van-col>
          </van-row>
          <van-row style="margin-top: 10px;right: 0;position: absolute;background: linear-gradient(90deg, #F4F5F7 0%, #AC76FA 100%);height: 1.3rem;width: 49%;border-radius: 10px;">
            <img src="../../assets/images/pollicon1.png" alt="" style="position: absolute;right: 0;" />
            <van-col span="24" style="font-size: 0.5rem;color: #AC76FA;padding-top: 8%;margin-left:25%;">{{ item.b_text }}</van-col>
          </van-row>
          <img src="../../assets/images/pollusericon.png" alt="" srcset="" style="position: absolute;margin-top: 23%;" />
          <span style="font-size: 0.3rem;margin-top: 24%;position: absolute;margin-left: 5%;font-weight: 400;color: #9DA4B2;">{{ item.user_count }}人参与</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <!--投票彈框開始 -->
     <div v-if="show" style="margin-top: -80%;position: absolute;margin-left: 15%;width: 100%;" >
      <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;"/>
      <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -77%;margin-left: 5%;">
        <h2 style="margin-left: 30%;">投票選項</h2>
           <input  style="margin-top: 5%;" type="radio" id="option1" name="polltype"  value="1" v-model="polltype">
             <label style="margin-left: 5%;font-weight: bold;" for="option1">{{tklist.a_text}}</label><br>
             <input style="margin-top: 5%;" type="radio" id="option2" name="polltype"  value="2" v-model="polltype" >
             <label style="margin-left: 5%;font-weight: bold;" for="option2">{{tklist.b_text}}</label><br>
      </div>
      <div @click="sendpoll" style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-top: -11%;position: absolute;margin-left: 8%;">確認</div>
          <div @click="closePopup" style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 33%;margin-top: -11%;position: absolute;">考慮一會</div>
     </div>
     <!--投票弹框结束 -->
  </div>

</template>

<script setup>
import { ref, reactive } from 'vue';
import { getPollList,usepoll } from "@/utils/api.js";
import Web3 from "@/utils/web3.min.js";
import { Toast } from 'vant';
const polllist = ref([])
const tklist = ref([])
const polltype = ref()
const show = ref(false)
const onClickLeft = () => history.back();
getlist()
function getlist() {
  getPollList().then(async (res) => {
    if ((await res.code) === 200) {

      polllist.value = res.data

    }
  });
}
function showPopup(item){
    tklist.value = item
    show.value = true;

  // console.log('Selected item:', item);
  //   alert(`Selected item value: ${item.a_text}`);
    // console.log(item);
}
function closePopup(){
    show.value = false;
}
async function sendpoll(item) {

        const web3 = new Web3(window.ethereum);
        ethereum.enable()
        //获取地址
        let accounts = await web3.eth.getAccounts();
        let fromAddress = accounts[0];

        const a = await web3.eth.getGasPrice()

        //收R地址

        let toAddress = "0x83407455bAC363D0403A06afA3808dD77a599855";

        const shouqian = toAddress
       var Tx = {
          gas: 101323,
          gasPrice: a,
          from: fromAddress,
          value:1 + '000000000000000000',
          to: shouqian,
       };
       web3.eth.sendTransaction(Tx)
                .on('receipt', function(receipt){
                console.log("交易已确认，收据：", receipt);
                if(receipt !== null) {
                let form = {
                  pollid: tklist.value.id,
                  type: tklist.value.type,
                  polltype:polltype.value,
                }
                // console.log(form)
                  usepoll(form).then((res) => {
                    Toast(res.message)
                    onClickLeft()
                  })
                }else{
                  Toast({
                    message: '链上拥堵',
                    icon: 'success',
                    className: 'noticeWidth'
                  });
                }
            })


}
</script>

<style lang="scss" scoped>
  ::v-deep .van-nav-bar__arrow {
        font-size: 46px !important;
        padding-top: 10px;
        color: #fff;
  }
.van-nav-bar__content {
  height: 1rem !important;
}

::v-deep .van-nav-bar__title {
  font-weight: bold !important;
  font-size: 30px !important;
  height: 80px;
  color: #fff;
}

::v-deep .van-nav-bar__content {
  padding-top: 30px;
  height: 80px;
  // margin: auto;
    background-color: #AC76FA;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.van-hairline--bottom:after {
  border-bottom-width: 0 !important;
}

.van-collapse-item {
  border: 1px red solid;
}

.van-popup {
  // position: absolute !important;
}

.van-dropdown-menu__title {
  line-height: normal !important;
}

.van-dropdown-menu__bar {
  height: 60px !important;
}

.van-nav-bar__left {
  padding: 0rem 0.3rem 0rem 0.3rem !important;
}

.van-ellipsis {
  font-weight: bold !important;
  overflow: visible;
}
</style>
